.App-main {
    flex-grow: 1 !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.Feature-product-text-container {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.Feature-product-container {
    row-gap: 30px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-bottom: 50px !important;
    flex-grow: 1 !important;
}
html {
  margin: 0 !important;
  height: 100% !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  height: 100% !important;
  width: 100% !important;
  min-width: 100% !important;
  min-height: 100% !important;
  background-image: url(/static/media/BGImage.839bb24c.jpg) !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-attachment: fixed !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace !important;
}

#root {
  height: 100% !important;
  width: 100% !important;
}

.App-header {
    font-size: calc(10px + 2vmin) !important;
    width: 100% !important;
    max-width: 100% !important;
    padding: 15px 0px 15px 0px !important;
    background-color: rgba(32, 41, 56, 0.9) !important;
}

.Logo {
    height: 100% !important;
}
.App-main {
    flex-grow: 1 !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.Feature-product-text-container {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.Feature-product-container {
    grid-row-gap: 30px !important;
    row-gap: 30px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-bottom: 50px !important;
    flex-grow: 1 !important;
}
.App-footer {
    display: flex !important;
    justify-content: center !important;
    font-size: calc(10px + 2vmin) !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
}
.Footer-button-container {
    width: 400px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-left: 10px !important;
    margin-right: 10px !important;
}

.Footer-button {
    width: 500px !important;
    border-radius: 30px 0px 30px 0px !important;
    background-color: rgba(31, 41, 55, 0.9) !important;
}
.App {
  width: 100% !important;
  height: 100% !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
}





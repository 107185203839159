.Footer-button-container {
    width: 400px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-left: 10px !important;
    margin-right: 10px !important;
}

.Footer-button {
    width: 500px !important;
    border-radius: 30px 0px 30px 0px !important;
    background-color: rgba(31, 41, 55, 0.9) !important;
}
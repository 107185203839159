.App-header {
    font-size: calc(10px + 2vmin) !important;
    width: 100% !important;
    max-width: 100% !important;
    padding: 15px 0px 15px 0px !important;
    background-color: rgba(32, 41, 56, 0.9) !important;
}

.Logo {
    height: 100% !important;
}